import React from "react";
import ReppLogo from "../src/repp-brand-small.png";
import SeqfoldLogo from "../src/seqfold-brand-small.png";
import SeqvizLogo from "../src/seqviz-brand-small.png";
import SynbioLogo from "../src/synbio-brand-small.png";
import IcorLogo from "../src/icor-brand-small.png";
import "./App.css";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Toolcard } from "./Toolcard";

function App() {
  const toolsInfo = [
    {
      name: "seqviz",
      description: "DNA sequence viewer supporting standard input.",
      logoSource: SeqvizLogo,
      applicationLink: "https://tools.latticeautomation.com/seqviz/",
      downloadLink: "https://unpkg.com/seqviz/dist/seqviz.min.js",
      githubLink: "https://github.com/Lattice-Automation/seqviz",
      tags: ["visualization", "dna", "sequence", "javascript"],
      license: "MIT",
      ui: true,
    },
    {
      name: "synbio",
      description: "DNA assembly automation.",
      logoSource: SynbioLogo,
      applicationLink:
        "https://lattice-automation.github.io/synbio/html/index.html",
      githubLink: "https://github.com/Lattice-Automation/synbio",
      tags: [
        "protocols",
        "robotics",
        "dna-assembly",
        "plasmids",
        "annotation",
        "seqrecord",
        "picklist",
      ],
      license: "GPL-2.0",
      ui: false,
    },
    {
      name: "repp",
      description: "Repository-based plasmid design.",
      logoSource: ReppLogo,
      applicationLink: "https://lattice-automation.github.io/repp/",
      githubLink: "https://github.com/Lattice-Automation/repp",
      tags: [
        "plasmid",
        "gibson",
        "assembly",
        "dna",
        "repositories",
        "blast",
        "bioinformatics",
        "python",
      ],
      license: "GPL-2.0",
      ui: false,
    },
    {
      name: "seqfold",
      description: "Pythonic nucleic acid folding.",
      logoSource: SeqfoldLogo,
      applicationLink: "https://github.com/Lattice-Automation/seqfold",
      githubLink: "https://github.com/Lattice-Automation/seqfold",
      tags: ["structure", "dna", "rna", "folding", "dg"],
      license: "MIT",
      ui: false,
    },
    {
      name: "icor",
      description: "Codon optimization with recurrent neural networks.",
      logoSource: IcorLogo,
      applicationLink: "https://github.com/Lattice-Automation/icor-codon-optimization",
      githubLink: "https://github.com/Lattice-Automation/icor-codon-optimization",
      tags: ["codon-optimization", "codon", "deep-learning", "benchmark"],
      license: "MIT",
      ui: false,
    },
  ];

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <div className="section-wrapper" id="section-overview">
          <div className="landing-section">
            <div className="section-content">
              <h1>Software tools for synthetic biology</h1>
              <p>
                Lattice creates software to enable synthetic biology workflows,
                pipelines, and best practices.
              </p>
            </div>
          </div>
        </div>
        <div className="section-wrapper">
          <div className="landing-section" id="tools-row">
            <div className="section-content">
              {toolsInfo.map((tool, key) => {
                return <Toolcard key={key} toolInfo={tool} />;
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
