import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import BiotoolsLogo from "../src/biotools-brand-small.png";
import "./Header.css";

export class Header extends Component {
  state = {};

  render() {
    return (
      <div className="app-header">
        <Image id="brand" src={BiotoolsLogo} floated="right" />
        <div id="header-actions" />
      </div>
    );
  }
}
