import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import "./Footer.css";

export class Footer extends Component {
  render() {
    return (
      <div className="footer-wrapper">
        <div className="app-footer">
          <div id="section-copyright">
            <p>Copyright &copy; Lattice Automation 2020</p>
          </div>
          <div id="section-connect">
            <Button
              className="la-button"
              icon="github"
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/Lattice-Automation/"
            />
            <Button
              className="la-button"
              icon="medium"
              target="_blank"
              rel="noopener noreferrer"
              href="https://medium.com/@lattice.core/"
            />
            <Button
              className="la-button"
              icon="youtube"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCNCj0uZ0sb4L9PoiSpZiuag"
            />
          </div>
          <div id="section-subscribe">
            <p>Sign up to receive updates about Lattice products</p>
            <Button
              className="la-button"
              id="button-subscribe"
              target="_blank"
              rel="noopener noreferrer"
              href="http://eepurl.com/gANroH"
            >
              Subscribe
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
